<template>
  <div class="page">
    <div class="coupon_c">
      <div class="input">
        <el-input placeholder="请输入书籍标识码" v-model="book_num"></el-input>
      </div>
      <div class="remarks">
        注：标识码详见院校篇封面处，该标识码仅可使用一次，领取成功后可在个人中心查看我的优惠券
      </div>
      <div class="btn" @click="receive()">
        一键领取优惠券
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      book_num: "",
    };
  },
  
  
  methods: {
    receive() {
      let data = {
        key: this.book_num,
      };
      this.$fecth.post("coupon/receiveCoupon", data).then((res) => {
        if (res) {
          this.$notify({
            showClose: true,
            message: "恭喜你，领取成功！",
            type: "success",
          });
          setTimeout(() => {
            this.$router.push("/");
          }, 1000);
        }
      });
    },
  },
};
</script>

<style lang='less'>
.coupon_c {
  height: 485px;
  width: 560px;
  margin: 80px auto;
  overflow: hidden;
  background: url("./../assets/img/coupon/coupon_bg.jpg");
  .input {
    width: 400px;
    margin: 0 auto;
    margin-top: 285px;
  }
  .remarks {
    width: 400px;
    margin: 15px auto;
    color: rgb(102, 102, 102);
  }
  .btn {
    width: 195px;
    height: 37px;
    margin: 0 auto;
    text-align: center;
    line-height: 37px;
    color: white;
    background: url("./../assets/img/coupon/coupon_btn.png");
  }
}
</style>
